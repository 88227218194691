export const MENUITEMS = [
  {
    // menutitle: "General",
    // menucontent: "Dashboards,Widgets",
    Items: [
      { path: "", icon: "home", type: "link", active: false, title: "Dashboard" },
      // {
      //   title: "Dashboard",
      //   icon: "home",
      //   type: "sub",
      //   badge: "badge badge-light-primary",
      //   badgetxt: "5",
      //   active: false,
      //   children: [
      //     { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: "Default", type: "link" },
      //     { path: `${process.env.PUBLIC_URL}/dashboard/e-commerce`, title: "E-commerce", type: "link" },
      //     { path: `${process.env.PUBLIC_URL}/dashboard/online-course`, title: "Online Course", type: "link" },
      //     { path: `${process.env.PUBLIC_URL}/dashboard/crypto`, title: "Crypto", type: "link" },
      //     { path: `${process.env.PUBLIC_URL}/dashboard/social`, title: "Social", type: "link" },
      //   ],
      // },
    ],
  },

  {
    Items: [
      { path: "/drivers", icon: "user", type: "link", active: false, title: "Drivers" },
      { path: "/driver-rides", icon: "project", type: "link", active: false, title: "Driver Rides" },
      { path: "/users", icon: "user", type: "link", active: false, title: "Users" },
      { path: "/user-rides", icon: "calendar", type: "link", active: false, title: "User Rides" },
    ],
  },
];
