import { Breadcrumbs, H4 } from "../../AbstractElements";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import SvgIcon from "../Common/Component/SvgIcon";
import { getDashboardAnalysis } from "../../Services/AdminService";
import H3 from '../../CommonElements/Headings/H3Element';

const Dashboard = () => {
    const [analysis, setAnalysis] = useState({
        user_count: 0,
        driver_count: 0,
        created_rides: 0,
        completed_rides: 0,
    });

    const fetchDashboardAnalysis = async () => {
        const res = await getDashboardAnalysis();

        setAnalysis(res.data);
    };

    useEffect(() => {
        fetchDashboardAnalysis();
    }, []);

    return (
        <Fragment>
            {/* <Breadcrumbs mainTitle="Dashboard" /> */}
            <Container fluid={true}>
                <div className='page-title'>
                <Row>
                    <Col xs='6'>
                        <H3>Dashboard</H3>
                    </Col>
                </Row>
                </div>
            </Container>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xl="6" lg="6" md="12">
                                        <Card className="widget-1" style={{ backgroundColor: "#f73164" }}>
                                            <CardBody>
                                                <div className="widget-content">
                                                    <div className="widget-round secondary">
                                                        <div className="bg-round">
                                                            <SvgIcon className="svg-fill" iconId="user-visitor" />
                                                            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <H4>{analysis.user_count}</H4>
                                                        <span className="f-light">Total Users</span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl="6" lg="6" md="12">
                                        <Card className="widget-1" style={{ backgroundColor: "#7366ff" }}>
                                            <CardBody>
                                                <div className="widget-content">
                                                    <div className="widget-round primary">
                                                        <div className="bg-round">
                                                            <SvgIcon className="svg-fill" iconId="user-visitor" />
                                                            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <H4>{analysis.driver_count}</H4>
                                                        <span className="f-light">Total Drivers</span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl="6" lg="6" md="12">
                                        <Card className="widget-1" style={{ backgroundColor: "#ffaa05" }}>
                                            <CardBody>
                                                <div className="widget-content">
                                                    <div className="widget-round warning">
                                                        <div className="bg-round">
                                                            <SvgIcon className="svg-fill" iconId="bag" />
                                                            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <H4>{analysis.created_rides}</H4>
                                                        <span className="f-light">Total Created Rides</span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl="6" lg="6" md="12">
                                        <Card className="widget-1" style={{ backgroundColor: "#54ba4a" }}>
                                            <CardBody>
                                                <div className="widget-content">
                                                    <div className="widget-round success">
                                                        <div className="bg-round">
                                                            <SvgIcon className="svg-fill" iconId="orders" />
                                                            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <H4>{analysis.completed_rides}</H4>
                                                        <span className="f-light">Total Completed Rides</span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Dashboard;
