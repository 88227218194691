import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllUsers } from "../../Services/UserService";
import Spinner from 'react-bootstrap/Spinner';

const UserTable = () => {
    const Columns = [
        {
            name: "User Name",
            selector: (row) => row["full_name"],
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Contact",
            selector: (row) => row["phone"],
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Email",
            selector: (row) => row["email"],
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
    ];

    const [userList, setUserList] = useState([]);
    const [pending, setPending] = useState(true);

    const getAllUserList = async () => {
        try {
            const res = await getAllUsers();
            setUserList(res.data);
        } catch (error) {
            console.log(error);
        } finally {
            setPending(false)
        }
    };

    useEffect(() => {
        getAllUserList();
    }, []);

    return (
        <Fragment>
            <div className="table-responsive support-table">
                { 
                    pending ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : 
                    (
                        <DataTable
                            columns={Columns}
                            data={userList}
                            striped={true}
                            center={true}
                            pagination
                        />
                    )
                }
            </div>
        </Fragment>
    );
};

export default UserTable;
